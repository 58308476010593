import React, {
  type JSX,
  type FunctionComponent,
  type MouseEventHandler,
} from "react";
import classNames from "classnames";

type ButtonProps = (
  | {
      onClick: MouseEventHandler<HTMLButtonElement>;
      disabled?: boolean;
    }
  | {
      href: string;
    }
) & {
  className?: classNames.Argument;
  children?: JSX.Element | string;
};

/* eslint-disable react/destructuring-assignment */
const Button: FunctionComponent<ButtonProps> = (props) =>
  React.createElement(
    "href" in props ? "a" : "button",
    "href" in props
      ? {
          className: classNames("btn", props.className),
          href: props.href,
        }
      : {
          className: classNames("btn", props.className),
          onClick: props.onClick,
          disabled: props.disabled,
        },
    props.children
  );
/* eslint-enable react/destructuring-assignment */

Button.defaultProps = {
  className: undefined,
  children: undefined,
};

export default Button;
